//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {Message} from "element-ui";
import {fetchCheckWorkList} from "@/api/checkWork";
import {fetchFilter} from "@/api/master";
import func from "@/utils/decoded";
import moment from "moment/moment";

const fields = [
	{key: 'contract_id', label: 'เลขที่สัญญา', _style: 'min-width:100px'},
	{key: 'name_customer', label: 'ชื่อ-สกุล', _style: 'min-width:150px;'},
	{key: 'startDate', label: 'วันที่เริ่มทำสัญญา', _style: 'min-width:100px;'},
	{key: 'balance', label: 'ยอดเงินคงเหลือ', _style: 'min-width:100px; text-align: right;'},
	{key: 'status', label: 'สถานะ', _style: 'min-width:100px;'},
	{key: 'users', label: 'ผู้รับผิดชอบ', _style: 'min-width:50px;'},
	{
		key: 'appointment_day',
		label: 'กำลังจะถึงวันนัดหมาย',
		_style: 'min-width:150px; text-align: center;',
		sorter: false,
		filter: false
	}
]

export default {
	name: 'CheckWork',
	data() {
		return {
			options: ['ทั้งหมด', 'รอนัดหมายลูกค้า', 'นัดหมายลูกค้าเรียบร้อย', 'โอนรถเรียบร้อย', 'โอนรถไม่เรียบร้อย'],
			items: [],
			fields,
			searchParams: {
				keyword: '',
				startDate: '',
				endDate: '',
				filter: 0
			}
		}
	},
	created() {
		document.title = this.$route.name + " : ONE Tracemoney"
		const check = func.checkMenu(this.$route.path)
		if (check && check.length === 0) {
			this.$router.push({path: '/404'})
		}
	},
	watch: {
		$route: {
			immediate: true,
			handler(route) {
				if (route.query && route.query.page) {
					this.activePage = Number(route.query.page)
				}
			}
		}
	},
	async mounted() {
		this.$store.state.loading = true
		try {
			this.searchParams.endDate = moment().format('YYYY-MM-DD')
			this.searchParams.startDate = moment().subtract(6, 'months').format('YYYY-MM-DD')
			await new Promise((resolve, reject) => {
				const param = {
					'stepId': 32
				}
				fetchFilter(param).then(response => {
					const check = response.header
					const data = response.body
					if (check.error === 'N') {
						this.options = data
						resolve()
					}
				}).catch(error => {
					this.errorMsg = error
					console.log(error)
					reject(error)
				})
			})
			await this.getContractData()
		} finally {
			this.$store.state.loading = false
		}
	},
	methods: {
		getContractData() {
			fetchCheckWorkList(this.searchParams).then(response => {
				this.items = []
				const check = response.header
				const data = response.body
				if (check.error === 'N') {
					for (let item of data) {
						if (item.status_id === 1)
							item.status = 'โอนรถ[ไม่เรียบร้อย]'
						else if (item.status_id === 2)
							item.status = 'โอนรถ[เรียบร้อย]'
						else if (item.status_id === 4)
							item.status = 'นัดหมายลูกค้า[เรียบร้อย]'
						else
							item.status = 'รอนัดหมายลูกค้า'
						this.items.push(item)
					}
				}
			}).catch(error => {
				Message({
					message: 'มีข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ',
					type: 'error',
					duration: 5 * 1000,
				})
				this.errorMsg = error
				console.log(error)
			})
		},
		getBadge(statusId) {
			switch (statusId) {
				case 1:
					return 'danger'
				case 2:
					return 'success'
				case 4:
					return 'info'
				case null:
					return 'warning'
				default:
					return 'primary'
			}
		},
		pageChange(val) {
			this.$router.push({query: {page: val}})
		}
	}
}
